import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { EnvironmentProvider } from './providers/EnvironmentProvider';
import { SearchProvider } from './providers/SearchProvider';
import { HotelProvider } from './providers/HotelProvider';
import { AvailabilityCalendarProvider } from './providers/AvailabilityCalendarProvider';
import { NumDaysProvider } from './providers/NumDaysProvider';
import { TicketTypesProvider } from './providers/TicketTypesProvider';
import { ProductProvider } from './providers/ProductProvider';
import { SearchFilters } from './components/SearchFilters';
import { Spinner } from './components/Spinner';
import { ToggleLanguage } from './components/ToggleLanguage';
import { ABContextProvider } from './providers/ABContextProvider';
import './App.scss';

// Lazy load the app whilst the search filters are being set up.
const LazyAssistedNavigation = React.lazy(() => import('./components/AssistedNavigation').then(({ AssistedNavigation }) => ({ default: AssistedNavigation })));

// Initialise React Query Client.
const queryClient = new QueryClient();

const App = () => (
  <ABContextProvider>
    <QueryClientProvider client={queryClient}>
      <EnvironmentProvider>
        <SearchProvider>
          <NumDaysProvider>
            <HotelProvider>
              <AvailabilityCalendarProvider>
                <TicketTypesProvider>
                  <ProductProvider>
                    <SearchFilters />
                    {process.env.NODE_ENV === 'development' && <ToggleLanguage />}
                    <Suspense fallback={<Spinner />}>
                      <LazyAssistedNavigation />
                    </Suspense>
                  </ProductProvider>
                </TicketTypesProvider>
              </AvailabilityCalendarProvider>
            </HotelProvider>
          </NumDaysProvider>
        </SearchProvider>
      </EnvironmentProvider>
      { process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} /> }
    </QueryClientProvider>
  </ABContextProvider>
);

export default App;
