/**
 * Formats price according to domain and currency
 * Example: de-DE & EUR => 23.456,78 €, en-EN & GBP => £23,456.78
 *
 * @param {*} price
 * Price to be formatted
 * @param {*} searchContext
 * Search context has lang and currency values needed for this function
 */
const formatPrice = (price, searchContext) => {
  let options = {
    style: 'currency',
    currency: searchContext.sale_cur,
    currencyDisplay: 'symbol',
    maximumFractionDigits: 0, // gets rid of trailing zeros
  };

  if (Number(price) % 1 !== 0) {
    options = {
      style: 'currency',
      currency: searchContext.sale_cur,
      currencyDisplay: 'symbol',
    };
  }

  const langId = searchContext.lang;

  let formattedLandId = '';

  // Format lang ID to xx-XX
  // Example: en-EN, en-AU, de-DE
  if (langId === 'en') {
    formattedLandId = `${langId}-${langId.toUpperCase()}`;
  } else {
    const splitLang = langId.split('-');
    formattedLandId = `${splitLang[0]}-${splitLang[1].toUpperCase()}`;
  }

  let formattedPrice = new Intl.NumberFormat(formattedLandId, options).format(Number(price));

  // Add "A" for Australian Dollar
  if (langId === 'en-au') {
    formattedPrice = `A${formattedPrice}`;
  }

  return formattedPrice;
};

export default formatPrice;
